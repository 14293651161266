module.exports = [{
      plugin: require('/codebuild/output/src018554683/src/node_modules/gatsby-remark-autolink-headers/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/codebuild/output/src018554683/src/node_modules/gatsby-plugin-nprogress/gatsby-browser'),
      options: {"plugins":[],"color":"#c62828"},
    },{
      plugin: require('/codebuild/output/src018554683/src/node_modules/gatsby-plugin-catch-links/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/codebuild/output/src018554683/src/node_modules/gatsby-plugin-typography/gatsby-browser'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography.js"},
    },{
      plugin: require('/codebuild/output/src018554683/src/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
